import React from "react"

import Page from "../../components/Page"
import BannerParallax from "../../components/BannerParallax"

import imgBackground from "../../images/banner/banner-5-about-us.jpg"
import AboutUs from "./AboutUs"

export default () => {
  const pageTitle = "About Us"
  return (
    <Page title={pageTitle}>
      <BannerParallax title={pageTitle} background={imgBackground} />
      <AboutUs />
    </Page>
  )
}
